import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import BottomLeftCorner from "../svg/bottom_left_corner.svg";
import BottomRightCorner from "../svg/bottom_right_corner.svg";
import MainSectionLogo from "../svg/main_section_logo.svg";
import TopLeftCorner from "../svg/top_left_corner.svg";
import TopRightCorner from "../svg/top_right_corner.svg";
import { FRONT_DOMAIN } from "../config";

import ChangeLanguage from "../components/changeLanguage";

import "../scss/main.scss";
import {useEffect} from "react";

const GenerativeAuraCnPage = ({location}) => {

    React.useEffect(() => {
        const scrollContainer = document.querySelector('.site-wrapper');

        if(scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('_without-layout');
    }, []);

    return (
        <>
            <Helmet>
                <title>Generative Aura | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <section className="installation-img-section">
                <img className="" src="/static/img/1_GenerativeAura01.jpg" alt=""/>
            </section>



            <section className="site-section site-section--installation">

                <ChangeLanguage location={location}/>

                <div className="content-section" style={ { "--width": "72rem" } }>

                     <h2 className="content-section__title">GENERATIVE AURA</h2>

                    <div className="content-section__text">
                        <p>GENERATIVE AURA 是由澳洲多媒體設計團隊IKONIX創作的多媒體互動展品。當大家走到展區，通過識別互動者的人體骨架和活動反射，並將其的動作以數碼虛擬化及抽象圖形反射在數碼長廊內，製造出獨一無二的電子倒影。</p>
                    </div>


                    <div className="corners">
                        <p>請將身體到投影前移動，投影會跟著你身體的動作製造出你的電子倒影</p>

                        <TopLeftCorner className="corners__corner corners__corner_top-left" />
                        <TopRightCorner className="corners__corner corners__corner_top-right" />
                        <BottomRightCorner className="corners__corner corners__corner_bottom-right" />
                        <BottomLeftCorner className="corners__corner corners__corner_bottom-left" />
                    </div>

                    <div className="green">
                        <h2 className="content-section__title">藝術團隊 — IKONIX</h2>
                        <div className="content-section__text">
                            <p>IKONIX 是一個由澳大利亞多媒體和製作專業人士組成的團隊。他們通過視頻、燈光、建築和音頻創作精美的裝置。 IKONIX 專注於超高分辨率投影和尖端 LED 技術，將環境和建築帶入了一種全新的境界，遍及澳大利亞和世界，現在終於在香港。</p>
                        </div>
                    </div>

                    <MainSectionLogo className="content-section__logo" />

                </div>
            </section>
        </>
    )
}

export default GenerativeAuraCnPage;
